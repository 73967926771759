import axios from "axios";

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
        const [key, value] = param.split("=");
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
        return params;
      }, {})
    : {};
};

export function registerOneSignal() {
  if (window.localStorage) {
    const params = getQueryStringParams(window.location.search.substring(1));
    if ("os_disabled" in params && params.os_disabled === "1") {
      window.localStorage.setItem("os_disabled", "true");
    }

    const osDisabled = window.localStorage.getItem("os_disabled");
    if (osDisabled && osDisabled === "true") {
      return;
    }

    if ("nopush" in params && params.nopush === "1") {
      return;
    }

    if (import.meta.env.VITE_ONESIGNAL_ID === "") {
      return;
    }

    window.OneSignal = window.OneSignal ?? [];
    window.OneSignal.push(() => {
      window.OneSignal.init({
        appId: import.meta.env.VITE_ONESIGNAL_ID,
        allowLocalhostAsSecureOrigin: import.meta.env.MODE !== "production"
      });
    });

    localStorage.setItem("OneSignalAppId", import.meta.env.VITE_ONESIGNAL_ID);
    window.OneSignal.push(function () {
      window.OneSignal.on("subscriptionChange", function (isSubscribed) {
        if (isSubscribed === true) {
          window.OneSignal.getUserId()
            .then(function (userId) {
              localStorage.setItem("OneSignalUserId", userId);
              axios.post("/api/user/ontrapost", {
                appId: import.meta.env.VITE_ONESIGNAL_ID,
                userId: userId,
                hostname: window.location.hostname
              });
            })
            .catch(function (x) {
              console.log("ontraport err", x);
            });
        }
      });
    });
  }
}

if (import.meta.env.MODE === "production") {
  registerOneSignal();
}
